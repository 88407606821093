.links {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logout {
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
}

.menuButton,
.mobileLinks {
  display: none;
}

@media (max-width: 768px) {
  .links {
    display: none;
  }

  .menuButton {
    display: block;
    cursor: pointer;
  }

  .mobileLinks {
    position: absolute;
    top: 100px;
    right: 0;
    width: 50%;
    height: calc(100vh - 100px);
    background-color: var(--bg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}
